/**
 * Error service
 * Process thrown errors
 */

import * as Sentry from '@sentry/browser'
import {Router} from 'i18n'
import {isEmptyObject, objectToQueryString} from 'common/helpers'

// capture exception
export function captureException(error) {
  if (__PRODUCTION__) {
    Sentry.captureException(error)
  }
}

export function handleError(error, ctx) {
  if (__PRODUCTION__) {
    if (ctx.res) {
      const query =
        'query' in ctx && !isEmptyObject(ctx.query) ? `${objectToQueryString(ctx.query)}` : ''
      // eslint-disable-next-line no-magic-numbers
      ctx.res.writeHead(302, {Location: `/error${query}`})
      ctx.res.end()
    } else {
      Router.push('/error')
    }
  } else {
    console.error(error)
  }
}
