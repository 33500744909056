import {v1} from 'uuid'

const eventEmmiter = {
  events: {},
  dispatch: function (event, data) {
    if (!this.events[event]) return
    this.events[event].forEach(event => {
      event.callback(data)
    })
  },
  subscribe: function (event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    const id = v1()
    this.events[event].push({id, callback})

    return {id, event}
  },
  unsubscribe: function (event, id) {
    if (!this.events[event]) {
      return
    }

    this.events[event] = this.events[event].filter(event => event.id !== id)
  }
}

module.exports = {eventEmmiter}
