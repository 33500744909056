export const updateSessionStorage = router => {
  const USER_INTERACTIONS = 'userInteractions',
    MAX_HIST_SIZE = 3
  const {asPath} = router

  const storageItems = window.sessionStorage.getItem(USER_INTERACTIONS)
  const prevHist = storageItems !== null ? JSON.parse(storageItems) : null
  const history = prevHist === null ? [asPath] : [...prevHist.history, asPath]

  const prevPath = history[history.length - 2] || ''
  const historyObject = {
    prevPath,
    isLandingPage: !prevPath,
    history: history.slice(-MAX_HIST_SIZE)
  }

  window.sessionStorage.setItem(USER_INTERACTIONS, JSON.stringify(historyObject))
}

const defaultSessionObject = {
  prevPath: '/',
  isLandingPage: true,
  history: []
}

export const getSessionHistory = () => {
  const storageItems = window.sessionStorage.getItem('userInteractions')
  return storageItems !== null ? JSON.parse(storageItems) : defaultSessionObject
}
