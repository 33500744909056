/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable no-console */
import React, {useState, useEffect, useContext} from 'react'
import {
  CHECKOUT,
  PRODUCT_TYPES,
  PROMO_CODE_VALIDATION_STATUSES,
  MIN_PAYMENT_AMOUNT
} from 'consts.json'
import {isNumeric, resolveThumbnail, isEmptyObject} from 'common/helpers'
import {CPSocketContext} from '../../CPSocket/CPSocketProvider'
import {getItemsPrices} from 'services/salesService'
import Storage from 'services/storageService'
import {eventEmmiter} from 'common/eventEmmiter.js'
import {OK} from 'http-status-codes'
import {captureException} from 'services/errorService'

const initialValue = {
  // Helpers
  itemsCount: 0,
  free: false,
  disabled: true,
  checkoutStep: 0,
  paymentType: null,
  userType: null,
  timestamp: Date.now(),
  // Order
  order: {
    items: [],
    currency: CHECKOUT.DEFAULT_CURRENCY,
    promoCode: null,
    promotionDiscountType: '',
    promotionDiscountValue: 0,
    subtotal: 0,
    promotionDiscountAmount: 0,
    total: 0,
    donationShareAmount: 0,
    fixedPromotionRemainingAmount: 0,
    userId: null,
    userName: '',
    userEmail: '',
    birthdayTimestamp: null,
    taxRefundCountry: '',
    taxRefundZip: '',
    taxRefundCity: '',
    taxRefundStreetAddress: ''
  }
}

export type Context = {shoppingCart: Object}

export const defaultContext: Context = {
  shoppingCart: {...initialValue}
}

export const CPCartContext = React.createContext(defaultContext)

const eventListeners = []

export const CPCartProvider = props => {
  const socketContext = useContext(CPSocketContext)
  const {emitMessage, setNewCartId, socketInitalized} = socketContext
  const [cart, setCartPayload] = useState(initialValue)
  const [inProgress, setInProgress] = useState(false)
  const [storageService, setStorageService] = useState()
  const [storageInitalized, setStorageInitalized] = useState(false)

  const logAndSetCartPayload = val => {
    setCartPayload(val)
  }

  // On component mount instantiate storage service, and assign instance to storageService state variable
  useEffect(() => {
    const StorageInstance = new Storage('shoppingCart', initialValue, logAndSetCartPayload)
    StorageInstance.initStorage().then(() => {
      setStorageService(StorageInstance)
      setStorageInitalized(true)
    })

    eventListeners.push(eventEmmiter.subscribe('order_updated', event => onOderUpdated(event)))

    // On unmount deregister event listeners
    return () => {
      for (const event of eventListeners) {
        eventEmmiter.unsubscribe(event.event, event.id)
      }
    }
  }, [])

  const setCart = async payload => {
    await storageService.setStorageItem(payload)
  }

  const clearCart = async () => {
    await setNewCartId()
    await setCart({...initialValue})
  }

  const addToCart = async items => {
    const currentItems = [...cart.order.items]
    const newItems = Array.isArray(items) ? items : [items]
    const keepOnlyPaid = true

    const filteredItems = newItems.reduce((results, item) => {
      if (isValidCartItem(item, keepOnlyPaid))
        results.push({...item, quantity: 'quantity' in item ? item.quantity : 0})
      return results
    }, [])

    filteredItems.forEach(newItem => {
      const index = currentItems.findIndex(
        ({itemType, itemId}) => itemType === newItem.itemType && itemId === newItem.itemId
      )
      if (index > -1) {
        if (newItem.quantity === 0) {
          currentItems.splice(index, 1)
        } else {
          currentItems[index] = newItem
        }
      } else {
        if (newItem.quantity > 0) {
          currentItems.push(newItem)
        }
      }
    })

    await updateCart({
      payload: {
        order: {
          items: currentItems
        }
      }
    })
  }

  const deleteFromCart = async value => {
    const {order} = cart
    const {items} = order

    const currentItems = [...items]
    const itemsToBeDeleted = Array.isArray(value) ? value : [value]

    currentItems.map((item, itemIndex) => {
      const index = itemsToBeDeleted.findIndex(
        ({itemType, itemId}) => itemType === item.itemType && itemId === item.itemId
      )

      if (index > -1) {
        currentItems.splice(itemIndex, 1)
      }
      return currentItems
    })

    await updateCart({
      payload: {
        order: {
          items: currentItems
        }
      }
    })
  }

  const isItemInCart = item => {
    const {order} = cart
    const {items} = order

    const index = items.findIndex(
      ({itemType, itemId}) => itemType === item.itemType && itemId === item.itemId
    )
    return index > -1
  }

  const getItemFromCart = item => {
    const {order} = cart
    const {items} = order

    const index = items.findIndex(
      ({itemType, itemId}) => itemType === item.itemType && itemId === item.itemId
    )
    return items[index]
  }

  const getFormatedOrder = order => {
    return {
      items: order.items.map(item => {
        return {
          itemId: item.itemId,
          itemType: item.itemType,
          title: item.title,
          quantity: item.quantity,
          meta: item.meta
        }
      }),
      currency: order.currency,
      promoCode: order.promoCode && order.promoCode.length ? order.promoCode : null,
      userId: order.userId,
      userName: order.userName,
      userEmail: order.userEmail,
      birthdayTimestamp: order.birthdayTimestamp,
      taxRefundCountry: order.taxRefundCountry,
      taxRefundCity: order.taxRefundCity,
      taxRefundZip: order.taxRefundZip,
      taxRefundStreetAddress: order.taxRefundStreetAddress,
      platform: 'Web'
    }
  }

  const emitOrderUpdateRequested = order => {
    const formatedOrder = getFormatedOrder(order)
    emitMessage('order_update_requested', formatedOrder)
  }

  const onOderUpdated = payload => {
    const {status} = JSON.parse(payload)
    if (status !== OK) {
      captureException(payload)
    }
  }

  const updateCart = async ({payload}) => {
    const newPayload = await formatCart({...payload})
    await setCart({...newPayload})
  }

  const isValidCartItem = (item, keepOnlyPaid) => {
    return (
      !isEmptyObject(item) &&
      'itemId' in item &&
      'itemType' in item &&
      (keepOnlyPaid ? !item.isFree : true)
    )
  }

  const checkIfIncludesDonation = () => {
    const {order} = cart
    const {items} = order

    return items.some(item => item.isDonation)
  }

  const assignPricesToItems = (items, prices) => {
    const getItemPrice = (item, prices) => {
      const index = prices.findIndex(
        ({productType, productOriginId}) =>
          productType === item.itemType && productOriginId === item.itemId
      )
      return prices[index]
    }

    const itemsWithPrices = items.reduce((results, item) => {
      const itemPrice = getItemPrice(item, prices)
      results.push(formatItem({...item, ...itemPrice}))
      return results
    }, [])
    return itemsWithPrices
  }

  const reduceItems = items => {
    return items.reduce((results, item) => {
      results.push(item)
      return results
    }, [])
  }

  const getPricesQuery = (items, currency, promoCode) => {
    return {
      products: items.map(item => {
        return {
          originId: item.itemId,
          type: item.itemType,
          quantity: 'quantity' in item ? item.quantity : 0
        }
      }),
      currency,
      promoCode
    }
  }

  const calculateItemsCount = items => {
    return items.reduce((acc, item) => {
      if (isNumeric(item.quantity)) {
        return acc + item.quantity
      }
      return acc
    }, 0)
  }

  const isCartFree = (items, total) => {
    const checkForFree = items.reduce((results, item) => {
      results.push(item.isFree ? item.isFree : item.quantity)
      return results
    }, [])

    return checkForFree.some(e => e === true) && total <= 0
  }

  const isCartDisabled = (free, itemsCount, total) => {
    return free || itemsCount < 1 || (total > 0 && total < MIN_PAYMENT_AMOUNT)
  }

  const formatItem = item => {
    const formatedOrderItem = {
      // Genaral info
      itemId: item.itemId,
      itemType: 'itemType' in item ? item.itemType : PRODUCT_TYPES.TOUR,
      title: item.title,
      // Price info
      itemPrice: item.itemPrice,
      quantity: item.quantity,
      originPrice: item.originPrice,
      promotionDiscountAmount: item.promotionDiscountAmount,
      price: item.price,
      isDonation: 'isDonation' in item ? item.isDonation : false,
      isFree: 'isFree' in item ? item.isFree : item.itemPrice === 0,
      // Cart settings
      image: 'image' in item ? resolveThumbnail(item.image) : '/static/images/default.png',
      seoURL: 'seoURL' in item ? item.seoURL : '',
      isIndoor: 'isIndoor' in item ? item.isIndoor : true,
      minQuantity: 'minQuantity' in item ? item.minQuantity : CHECKOUT.MIN_QUANTITY,
      maxQuantity: 'maxQuantity' in item ? item.maxQuantity : CHECKOUT.MAX_QUANTITY,
      meta: 'meta' in item ? item.meta : {},
      grantsItemByCode: 'grantsItemByCode' in item ? item.grantsItemByCode : false
    }

    return formatedOrderItem
  }

  const formatOrder = async (newOrder, currentOrder) => {
    if (typeof newOrder === 'object' && newOrder !== null) {
      const currency = 'currency' in newOrder ? newOrder.currency : currentOrder.currency
      const promoCode = 'promoCode' in newOrder ? newOrder.promoCode : currentOrder.promoCode
      const promotionDiscountType =
        'promotionDiscountType' in newOrder
          ? newOrder.promotionDiscountType
          : currentOrder.promotionDiscountType
      const promotionDiscountValue =
        'promotionDiscountValue' in newOrder
          ? newOrder.promotionDiscountValue
          : currentOrder.promotionDiscountValue

      let items = currentOrder.items
      let subtotal = currentOrder.subtotal
      let promotionDiscountAmount = currentOrder.promotionDiscountAmount
      let total = currentOrder.total
      let donationShareAmount = currentOrder.total

      // Format order items
      if ('items' in newOrder) {
        const itemsPrices = await getItemsPrices(
          getPricesQuery(newOrder.items, currency, promoCode)
        )
        const itemsWithPrices = assignPricesToItems(newOrder.items, itemsPrices.prices)
        items = reduceItems(itemsWithPrices, currency, promoCode)
        subtotal = itemsPrices.subtotal
        promotionDiscountAmount = itemsPrices.promotionDiscountAmount
        total = itemsPrices.total
        donationShareAmount = itemsPrices.donationShareAmount
      }

      const fixedPromotionRemainingAmount =
        promotionDiscountValue - promotionDiscountAmount > 0
          ? promotionDiscountValue - promotionDiscountAmount
          : 0

      // New order
      const order = {
        items,
        currency,
        subtotal,
        promoCode,
        promotionDiscountType,
        promotionDiscountValue,
        promotionDiscountAmount,
        fixedPromotionRemainingAmount,
        total,
        donationShareAmount,
        userId: 'userId' in newOrder ? newOrder.userId : currentOrder.userId,
        userName: 'userName' in newOrder ? newOrder.userName : currentOrder.userName,
        userEmail: 'userEmail' in newOrder ? newOrder.userEmail : currentOrder.userEmail,
        birthdayTimestamp:
          'birthdayTimestamp' in newOrder
            ? newOrder.birthdayTimestamp
            : currentOrder.birthdayTimestamp,
        taxRefundCountry:
          'taxRefundCountry' in newOrder
            ? newOrder.taxRefundCountry
            : currentOrder.taxRefundCountry,
        taxRefundZip:
          'taxRefundZip' in newOrder ? newOrder.taxRefundZip : currentOrder.taxRefundZip,
        taxRefundCity:
          'taxRefundCity' in newOrder ? newOrder.taxRefundCity : currentOrder.taxRefundCity,
        taxRefundStreetAddress:
          'taxRefundStreetAddress' in newOrder
            ? newOrder.taxRefundStreetAddress
            : currentOrder.taxRefundStreetAddress
      }

      return order
    }

    return currentOrder
  }

  const formatCart = async (newCart, emitEventToPaymentAPI = true) => {
    // Check is valid object
    if (isEmptyObject(newCart)) {
      return {...initialValue}
    }

    // Create new cart from current cart
    const currentCart = {...cart}

    const checkoutStep = 'checkoutStep' in newCart ? newCart.checkoutStep : currentCart.checkoutStep
    const paymentType = 'paymentType' in newCart ? newCart.paymentType : currentCart.paymentType
    const userType = 'userType' in newCart ? newCart.userType : currentCart.userType

    let order = currentCart.order
    if ('order' in newCart) {
      const newOrder = await formatOrder(newCart.order, currentCart.order, emitEventToPaymentAPI)
      order = {...newOrder}

      if (emitEventToPaymentAPI) {
        setInProgress(true)
        emitOrderUpdateRequested(newOrder)
      }
    }

    const itemsCount = calculateItemsCount(order.items)
    const free = isCartFree(order.items, order.total)
    const disabled = isCartDisabled(free, itemsCount, order.total)

    return {
      ...currentCart,
      itemsCount,
      free,
      disabled,
      checkoutStep,
      paymentType,
      userType,
      timestamp: Date.now(),
      order
    }
  }

  const applyPromoCode = async promoCode => {
    const currentCart = {...cart}
    const cartItems = currentCart.order.items
    const currency = currentCart.order.currency

    const itemsPrices = await getItemsPrices(getPricesQuery(cartItems, currency, promoCode))

    const itemsWithPrices = assignPricesToItems(cartItems, itemsPrices.prices)

    // For every item, check i promo code applied successfully. Promo code depending on it's conditions,
    // can be applied to all items, to some items, or to none of them.
    const statuses = itemsPrices.prices.map(item => item.message)
    let status = PROMO_CODE_VALIDATION_STATUSES.NOT_APPLICABLE
    if (statuses.length && statuses.every(status => status === 'SUCCESSFULL')) {
      status = PROMO_CODE_VALIDATION_STATUSES.APPLIED_ON_ALL
    } else if (statuses.length && statuses.some(status => status === 'SUCCESSFULL')) {
      status = PROMO_CODE_VALIDATION_STATUSES.PARTIALY_APPLIED
    }

    // If promo code is applicabe to at leas one item, update cart and set promo code
    if (status !== PROMO_CODE_VALIDATION_STATUSES.NOT_APPLICABLE) {
      let promotionDiscountType = ''
      let promotionDiscountValue = 0

      for (let i = 0; i < itemsPrices.prices.length; i++) {
        if (itemsPrices.prices[i].message === 'SUCCESSFULL') {
          promotionDiscountType = itemsPrices.prices[i].promotionDiscountType
          promotionDiscountValue =
            itemsPrices.prices[i].promotionDiscountType === 'FIXED_VALUE'
              ? itemsPrices.prices[i].promotionDiscountFixedAmount
              : itemsPrices.prices[i].promotionDiscountPercentile
          break
        }
      }

      // If "items" argument is not provided, than promo code is applied current cart items.
      // Otherwise, apply promo code to current cart items (if applicable), and update cart.
      await updateCart({
        payload: {
          order: {
            items: itemsWithPrices,
            promoCode,
            promotionDiscountType,
            promotionDiscountValue
          }
        }
      })
    }

    return {
      status,
      items: itemsWithPrices
    }
  }

  const removePromoCode = async () => {
    const promoCode = null
    const promotionDiscountType = ''
    const promotionDiscountValue = 0

    const currentCart = {...cart}
    const cartItems = currentCart.order.items
    const currency = currentCart.order.currency

    const itemsPrices = await getItemsPrices(getPricesQuery(cartItems, currency, promoCode))
    const itemsWithPrices = assignPricesToItems(cartItems, itemsPrices.prices)

    await updateCart({
      payload: {
        order: {
          items: itemsWithPrices,
          promoCode,
          promotionDiscountType,
          promotionDiscountValue
        }
      }
    })
  }

  return (
    <CPCartContext.Provider
      value={{
        shoppingCart: {
          inProgress,
          setInProgress,
          cart,
          checkIfIncludesDonation,
          isItemInCart,
          getItemFromCart,
          clearCart,
          getFormatedOrder,
          formatCart,
          updateCart,
          addToCart,
          deleteFromCart,
          applyPromoCode,
          removePromoCode,
          cartInitalized: storageInitalized && socketInitalized
        }
      }}
    >
      {props.children}
    </CPCartContext.Provider>
  )
}

export default CPCartProvider
