/* eslint-disable no-console */
import React, {useState, useEffect} from 'react'
import io from 'socket.io-client'
import {v1} from 'uuid'
import config from 'config'
import Storage from 'services/storageService'
import {eventEmmiter} from 'common/eventEmmiter.js'
import {captureException} from 'services/errorService'

export const CPSocketContext = React.createContext()

export const CPSocketProvider = props => {
  const [cartId, setCartId] = useState()
  //
  const [socket, setSocket] = useState()
  const [socketConnected, setSocketConnected] = useState(false)
  //
  const [storageService, setStorageService] = useState()
  const [storageInitalized, setStorageInitalized] = useState(false)

  // On component mount instantiate storage service, and assign instance to storageService state variable
  useEffect(() => {
    const StorageInstance = new Storage('cartId', v1(), setCartId)
    StorageInstance.initStorage().then(() => {
      setStorageService(StorageInstance)
      setStorageInitalized(true)
    })
  }, [])

  // When cartId changes open new sockeIO connection
  useEffect(() => {
    if (cartId) {
      openSocketIOConnection()
    }
  }, [cartId])

  // Open socketIO connection to server, and assign connection to socket state variable
  const openSocketIOConnection = () => {
    if (socket) {
      socket.close()
    }

    try {
      const socketServerURL = config.API_SOCKET_BASE + '/websockets/cart'
      const newSocket = io(socketServerURL, {
        transportOptions: {
          polling: {
            extraHeaders: {'x-cart-id': cartId}
          }
        }
      })

      registerSocketIOEvents(newSocket).then(socket => {
        setSocket(socket)
        setSocketConnected(true)
      })
    } catch (error) {
      captureException(error)
    }
  }

  const emitMessage = (channel, payload) => {
    return new Promise((resolve, reject) => {
      try {
        if (socket) {
          socket.emit(channel, JSON.stringify({...payload, cartId: cartId}))
          resolve()
        } else {
          throw new Error('Socket is not connected')
        }
      } catch (error) {
        captureException(error)
        reject(error)
      }
    })
  }

  const setNewCartId = async () => {
    await storageService.setStorageItem(v1())
  }

  const registerSocketIOEvents = socket => {
    return new Promise(resolve => {
      // Event will occur when payment is created
      socket.on('order_updated', data => {
        eventEmmiter.dispatch('order_updated', data)
      })
      // Event will occur when payment is created
      socket.on('payment_created', data => {
        eventEmmiter.dispatch('payment_created', data)
      })
      // Event will occur when payment is compleated
      socket.on('payment_completed', data => {
        eventEmmiter.dispatch('payment_completed', data)
      })
      // Event will occur when payment fails
      socket.on('payment_failed', data => {
        eventEmmiter.dispatch('payment_failed', data)
      })
      // Event will occur when payment is cancelled
      socket.on('payment_cancelled', data => {
        eventEmmiter.dispatch('payment_cancelled', data)
      })
      // Event will occur if error happens in any step on payment
      socket.on('payment_error', data => {
        eventEmmiter.dispatch('payment_error', data)
        captureException(data)
      })
      resolve(socket)
    })
  }

  return (
    <CPSocketContext.Provider
      value={{
        socket,
        cartId,
        emitMessage,
        setNewCartId,
        socketInitalized: storageInitalized && socketConnected
      }}
    >
      {props.children}
    </CPSocketContext.Provider>
  )
}

export default CPSocketProvider
