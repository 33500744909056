import config from 'config'
import {apiCall, createApiInstance, getHeader} from './cpApi'

export const CPSalesApi = createApiInstance(config.CP_SALES_BASE)

export const putAsync = async (url, data) => {
  return await CPSalesApi.put(url, data, getHeader())
}

export const put = (url, data, onSuccess = () => {}, onError = () => {}) => {
  apiCall(() => putAsync(url, data), onSuccess, onError)
}

export const postAsync = async (url, data, token = '') => {
  return await CPSalesApi.post(url, data, getHeader(token))
}

export const post = (url, data, onSuccess = () => {}, onError = () => {}) => {
  apiCall(() => postAsync(url, data), onSuccess, onError)
}

export const deleteAsync = async url => {
  return await CPSalesApi.delete(url, getHeader())
}

export const deleteReq = (url, onSuccess, onError) => {
  apiCall(() => deleteAsync(url), onSuccess, onError)
}

export const getAsync = async (url, token = '') => {
  return await CPSalesApi.get(url, getHeader(token))
}

export const get = (url, token, onSuccess = () => {}, onError = () => {}) => {
  apiCall(() => getAsync(url, token), onSuccess, onError)
}
