import {getAsync, postAsync} from './cpSalesApi'
import {objectToQueryString} from 'common/helpers'
import config from 'config'

export const isPromoCodeValid = async args => {
  const response = await getAsync(`promotion/isPromoCodeValid${objectToQueryString(args)}`)
  return response.data
}

export const getItemPrice = async args => {
  const response = await getAsync(`sales/price${objectToQueryString(args)}`)
  return response.data
}

export const getItemsPrices = async data => {
  const response = await postAsync('sales/prices', data)
  return response.data
}

export const getProduct = async (type, id) => {
  const response = await getAsync(
    `/product/${type.toLowerCase()}/${id}/get`,
    config.CP_SALES_ACCESS_TOKEN
  )
  return response.data
}

export const checkForPromoCode = async (type, id) => {
  const response = await getAsync(
    `/product/${type.toLowerCase()}/${id}/checkForPromoCode`,
    config.CP_SALES_ACCESS_TOKEN
  )
  return response.data
}
