import {createSlice} from '@reduxjs/toolkit'
import {HYDRATE} from 'next-redux-wrapper'

const initialState = {
  initialsBackgroundColor: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initInitialsBackgroundColor: state => {
      if (state.initialsBackgroundColor === null) {
        const BG_COLORS = ['#61B7CB', '#C82853', '#DB5811', '#659F17', '#FAA929']
        state.initialsBackgroundColor = BG_COLORS[Math.floor(Math.random() * BG_COLORS.length)]
      }
    }
  },
  extraReducers: {
    [HYDRATE]: (state, {payload}) => ({
      ...state,
      ...payload.user
    })
  }
})

// Action creators are generated for each case reducer function
export const {initInitialsBackgroundColor} = userSlice.actions

export default userSlice.reducer
