import * as localForage from 'localforage'

const storageName = 'cp'

export default class Storage {
  constructor(key, initialState, cb = undefined) {
    this.key = key
    this.initialState = initialState
    this.cb = cb
    this.storage = localForage.createInstance({name: storageName})
  }

  initStorage = async () => {
    const {driver} = this.storage

    if (driver !== undefined) {
      try {
        await this.storage.ready()
        await this.storage.setDriver([localForage.LOCALSTORAGE])
      } catch (e) {
        console.error('Storage on SSR Mode')
      }
    }

    let storageItem = await this.getStorageItem()

    if (!storageItem) {
      await this.setStorageItem(this.initialState)
      storageItem = this.initialState
    }

    // Register watch on storage item, in order to notify other tabs when change happens
    window.addEventListener('storage', this.onItemChanged)

    if (this.cb && typeof this.cb === 'function') {
      this.cb(storageItem)
    }
  }

  onItemChanged = async event => {
    if (event.key === `${storageName}/${this.key}` && this.cb && typeof this.cb === 'function') {
      const storageItem = await this.getStorageItem()
      this.cb(storageItem)
    }
  }

  getStorageItem = async () => {
    const item = await this.storage.getItem(this.key)
    if (!item) {
      return await this.setStorageItem(this.initialState)
    }
    return this.decodeLocalStorageItem(item)
  }

  setStorageItem = async item => {
    await this.storage.setItem(this.key, this.encodeLocalStorageItem(item))
    if (this.cb && typeof this.cb === 'function') {
      this.cb(item)
    }
    return item
  }

  encodeLocalStorageItem = value => {
    return Buffer.from(JSON.stringify(value)).toString('base64')
  }

  decodeLocalStorageItem = value => {
    return JSON.parse(Buffer.from(value, 'base64').toString('utf8'))
  }
}
